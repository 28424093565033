* {
    box-sizing: border-box;
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    // background: #9075A5;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.2rem 0 0 0.23rem;
    justify-content: space-between;
    z-index: 999;
    @media (max-width: 900px) {
        padding: 0.45rem 0.3rem;
    }
    .text {
        font-family: "Roboto", serif;
        font-weight: 500;
        font-size: 0.24rem;
        color: #ffffff;
        line-height: 0.35rem;
        opacity: 0.78;
    }
    .logo_box {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
            display: none;
        }
        .logo {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.08rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.08rem;
            }
        }
        .text {
            margin-left: 0.14rem;
            opacity: 0.78;
        }
    }
    .link_box {
        margin-left: 0.58rem;
        @media (max-width: 900px) {
            margin-left: 0;
            display: flex;
            width: 100%;
        }
        .text {
            color: #fff;
            padding: 0 0.47rem;
            @media (max-width: 900px) {
                display: block;
                width: 35%;
                font-size: 0.2rem;
                line-height: 0.3rem;
                padding: 0;
                text-align: center;
            }
            &:hover {
                color: #ff986b !important;
                opacity: 1;
            }
        }

        .ml {
            border-left: 0.03rem solid rgba(255, 255, 255, 0.1);
            border-right: 0.03rem solid rgba(255, 255, 255, 0.1);
        }
    }
}
.page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
   
    @media (max-width: 900px) {
        flex-direction: column;
        padding-top: 1.11rem;
        overflow-y: auto;
    }
    .box{
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 100%;
        @media (max-width: 900px) {
            flex-direction: column;
            padding-top: 1rem;
            height: 100%;
            overflow-y: auto;
        }
    }
    .left {
        padding-left: 7%;
        .title {
            font-family: "Roboto", serif;
            font-weight: 600;
            font-size: 1.12rem;
            color: #ffffff;
            line-height: 1.57rem;
            word-wrap: break-word;
            @media (max-width: 900px) {
                font-size: 0.84rem;
                line-height: 1.18rem;
            }
        }
        .tip {
            width: 80%;
            font-family: "Roboto", serif;
            font-weight: 500;
            font-size: 0.26rem;
            color: rgba(255, 255, 255, 0.5);
            line-height: 0.37rem;
            margin-top: 0.51rem;
            @media (max-width: 900px) {
                margin-top: 0.16rem;
                font-size: 0.22rem;
                line-height: 0.3rem;
                width: 90%;
            }
        }
        .app_store {
            width: 3.84rem;
            margin-top: 0.98rem;
            @media (max-width: 900px) {
                margin-top: 0.78rem;
            }
        }
    }
    .right {
        height: 100%;
        min-width: 7.31rem;
        display: flex;
        align-items: end;
        @media (max-width: 900px) {
            min-width: 80%;
            width: 100%;
            justify-content: flex-end;
            margin-top: 1.04rem;
            height: auto;
        }
        img {
            width: 100%;
            @media (max-width: 900px) {
                width: 90%;
            }
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/box_bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 900px) {
        background: url("../../assets/images/box_bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center center;
    }
    .pact-box {
        width: 75%;
        height: 80%;
        @media (max-width: 900px) {
            height: 88%;
        }
        .title {
            background: linear-gradient(23deg, #EE9657 0%, #FECEA0 28%, #EFBB9B 65%, #D79364 85%, #DFA274 100%);
            padding-top: 0.3rem;
            font-family: "Carattere", serif;
            font-weight: 400;
            font-size: 0.8rem;
            color: #73310e;
            line-height: 1.10rem;
            padding-bottom: 0.49rem;
            -webkit-background-clip: text;
            background-clip: text;
            color:transparent;
           
            @media (max-width: 900px) {
                padding-bottom: 0.33rem;
                font-size: 0.54rem;
                line-height: 0.64rem;
            }
        }
        .pact-cont {
            height: 80%;
            overflow: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @media (max-width: 900px) {
                height: 85%;
            }
            .text_box {
                display: flex;
                margin-top: 0.26rem;
                @media (max-width: 900px) {
                    flex-direction: column;
                }
                h2 {
                    width: 38%;
                    padding-right: 0.3rem;
                    font-family: "Heebo", serif;
                    font-weight: bold;
                    font-size: 0.28rem;
                    color: #fff;
                    line-height: 0.32rem;
                    margin-bottom: 0.3rem;
                    @media (max-width: 900px) {
                        font-size: 0.24rem;
                        line-height: 0.33rem;
                        width: 100%;
                        color: #fff;
                    }
                }
                .text {
                    flex: 1;
                    p,
                    li {
                        font-family: "Roboto", serif;
                        font-weight: normal;
                        font-size: 0.22rem;
                        color: #FFFFFF;
                        line-height: 0.30rem;
                        padding-bottom: 0.2rem;
                        @media (max-width: 900px) {
                            font-size: 0.24rem;
                            line-height: 0.34rem;
                            color: #fff;
                        }
                    }
                    strong {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
